import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import { resolveAbsoluteUrlInParagraphServer } from "utils/route";
import {
  FaqData,
  InfographicFaqAlignment,
  InfographicFaqCmsDataProps,
  InfographicFaqCmsProps,
  InfographicFaqDataProps,
} from "../dtos/index.type";

export const getInfographicFaqData = (
  cmsData: InfographicFaqCmsProps,
  languageCode: string,
  numberOfLang: number
): InfographicFaqDataProps => {
  const data: Maybe<InfographicFaqCmsDataProps> = cmsData?.reference_6?.[0];

  if (!data) {
    return {
      uid: "",
      displayTitle: "",
      subtitle: "",
      infographicFile: "",
      alignment: InfographicFaqAlignment.RIGHT,
      faq: [],
      faq_schema: [],
    };
  }

  return {
    uid: get(data, "uid", ""),
    displayTitle: get(data, "display_title", ""),
    subtitle: get(data, "subtitle", ""),
    infographicFile: replaceContentStackURL(
      get(data, "infographic_file.url", "")
    ),
    alignment: get(data, "alignment", InfographicFaqAlignment.RIGHT),
    faq: get(data, "faq", []).map((item: FaqData) => ({
      question: resolveAbsoluteUrlInParagraphServer(
        get(item, "question", ""),
        languageCode,
        numberOfLang
      ),
      answer: resolveAbsoluteUrlInParagraphServer(
        get(item, "answer", ""),
        languageCode,
        numberOfLang
      ),
    })),
    faq_schema: [],
  };
};
