import { SchemaTypes } from "components/HeaderSchema";

export type InfographicFaqCmsProps = {
  reference_6: InfographicFaqCmsDataProps[];
};

export type InfographicFaqCmsDataProps = {
  uid: string;
  display_title: string;
  subtitle: string;
  infographic_file: {
    url: string;
  };
  alignment: InfographicFaqAlignment;
  faq: FaqData[];
};

export type FaqData = {
  question: string;
  answer: string;
};

export type InfographicFaqDataProps = {
  uid: string;
  displayTitle: string;
  subtitle: string;
  infographicFile: string;
  alignment: InfographicFaqAlignment;
  faq: FaqData[];
  faq_schema: SchemaTypes[];
};

export enum InfographicFaqAlignment {
  RIGHT = "right",
  LEFT = "left",
}

export type FaqBlockProps = {
  displayTitle: string;
  subtitle: string;
  faq: FaqData[];
};

export type InfographicBlockProps = {
  infographicFile: string;
};
